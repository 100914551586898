
.section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.section-content {
    max-width: 600px;
    text-align: center; 
}

.section-header {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.section-text {
    font-size: 1.2rem;
    margin-bottom: 2rem; 
}

.title:hover{
    cursor:pointer
}
.nav-link:hover{
    cursor: pointer;
}
.landing-page-nav .nav-link.active {
    color: #fff !important; 
    position: relative; 
}

.landing-page-nav .nav-link.active::after {
    content: ""; 
    display: block;
    width: 100%;
    height: 3px;
    background-color: #007bff; 
    position: absolute; 
    bottom: -4px;
    left: 0; 
    transition: transform 0.2s ease; 
}

.landing-page-nav .nav-link.active:hover::after {
    transform: scaleX(1.1); 
}