
.ColorButton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
  }
  
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .switch .slider.round {
    border-radius: 34px;
    border: 2px solid white;

  }
  
  .switch .slider.round:before {
    border-radius: 50%;
  }
  
  .switch .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 3px;
    background-color: #000000;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  [data-bs-theme='light'] .switch .slider {
    background-color: #f1f1f1;
  }
  
  [data-bs-theme='light'] .switch .slider:before {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
  [data-bs-theme='dark'] .switch .slider {
    background-color: #000000;
  }
  
  [data-bs-theme='dark'] .switch .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #f1f1f1;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  .slider:before {
    border-radius: 50%;
  }
  