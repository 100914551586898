.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MainSection-container {
  margin-top: 2.5%;
  display: flex; 
  flex-direction: row; /* Default layout for desktop */
  align-content: flex-start;
  margin-left: 7.5%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .MainSection-container {
    margin-top: 80px;
    flex-direction: column; /* Stack elements vertically on mobile */
    margin-left: 0; 
  }

  .MapPane, .UtilNavBar {
    display: inline-flex; 
    flex-direction: row;
    width: 100%; 
  }

  .Sidebar {
    position: fixed; 
    width: 100%;
    height: auto; 
  }
}

.filler-div {
  width: 50%; 
}
