


.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; 
    padding: 48px 0 0; 
    box-shadow: inset -1px 0 0 rgba(255, 255, 255, 0.1);
    transition: width 0.2s;
    width: 50px;
    padding-top: 0;
    
}

.sidebar:hover {
    width: 150px;
}

.bg-dark {
    background-color: rgb(0, 0, 0) !important;
}

.sidebar-item {
    white-space: nowrap;
    overflow: hidden;
}

.nav-link {
    display: flex;
    align-items: center;
    color: white;
}

.home-icon {
    width: 24px; 
    height: 24px;
    margin-right: 5px; 
    padding-right: 5px;
}


.info-icon {
    width: 24px; 
    height: 24px;
    margin-right: 5px; 
    padding-right: 5px;
    fill: white;
}

.nav-label{
    margin-left: 7px;
    padding-left: 7px;
}

.sidebar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 5px; 
}

.color-toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; 
}
.color-toggle-circle {
    width: 15px; 
    height: 15px; 
    margin-right: 11px;
    border-radius: 50%;
    border: 2px solid white;
}

.color-toggle {
    display: none;
}

.sidebar:hover .color-toggle {
    display: block;
}

.sidebar:hover .color-toggle-circle {
    display: none;
}
.nav-label {
    display: none; 
    margin: 0; 
}

.sidebar:hover .nav-label {
    display: inline; 
}

.modal-custom {
    background-color: black;
    color: white;
  }
  
  /* Custom modal header and body to ensure text and other elements are styled correctly */
  .modal-custom .modal-header,
  .modal-custom .modal-body,
  .modal-custom .modal-footer {
    background-color: black;
    color: white;
  }
  
  /* Custom button styling */
  .button-custom {
    background-color: black;
    border: 0.5px solid white;
    color: white;
  }
  
  .button-custom:hover {
    background-color: #333; /* Slightly lighter black for hover effect */
    border-color: #fff;
    color: white;
  }



.mobile-nav-link {

    display: flex;
    align-items: center;
    padding: 8px 10px; 
}
.mobile-nav-link:hover{
    color: grey;
}

.navbar-dark .navbar-brand {
    color: white; 
}

.navbar-dark .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1); 
}

.mobile-home-icon, .mobile-info-icon {
    width: 24px; 
    height: 24px;
    margin-right: 15px;
    fill: white; 
}

.navbar-dark .nav-link span {
    margin-left: 7px;
    display: none; 
}

.mobile-color-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
}
