.vertical-navbar {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin: 10px 0;
    cursor: pointer;
    position: relative;
  }
  
  .icon::before {
    content: '';
    position: absolute;
    top: 35%;
    left: 47%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s ease;
    z-index: -1;  
    opacity: 0;  
    width: 55px; 
    height: 55px;  
  }
  
 
.icon.selected::before {
    content: '';
    position: absolute;
    top: 35%;
    left: 47%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: orange;
    opacity: 1;  
    width: 55px; 
    height: 55px;  
    z-index: -1;  
    transition: all 0.3s ease;
  }
  
  
  .icon .icon-svg {
    position: relative; 
    z-index: 0;  
    fill: white;  
  }


  html[data-bs-theme='light'] .icon-svg{
    fill: black;
  }

  html[data-bs-theme='dark'] .icon-svg{
    fill:white;
  }
  
  .icon:not(.selected):not(.part-of-group):hover .icon-svg {
    fill: orange;  
  }

  .icon.group-top-2::before, .icon.group-top-3::before, .icon.group-top-4::before {
    border-radius: 30px;  
    width: 50px;
  }
  
  .icon.group-top-2::before { /* group of 2 */
    height: 130px; 
    top: 60px;
  }
  
  .icon.group-top-3::before { /* group of 3 */
    height: 195px; 
    top: 90px;

  }
  
  .icon.group-top-4::before { /* group of 4 */
    height: 275px;
    top: 120px;
  }
  @media (max-width: 768px) {
    .vertical-navbar {
      flex-direction: row; /* Change to horizontal layout */
      justify-content: center; /* Align icons horizontally */
      align-items: center; /* Ensure vertical centering of icons */
      padding-right: 0; /* Adjust padding as needed */
      flex-wrap: wrap; /* Enable wrapping for multiple icons */
      width: 100%; /* Ensure navbar takes full width */
      margin-top: 5%;
    }
  
    .icon {
      margin: 5px; /* Adjust margins for better spacing */
    }
    .icon.group-top-2::before, .icon.group-top-3::before, .icon.group-top-4::before {
      border-radius: 30px;  
      height: 50px;
      width: 50px;
    }
    
    .icon.group-top-2::before { /* group of 2 */
      width: 130px; 
      height: 50px;

      top: 15px;
      left: 60px
    }
    
    .icon.group-top-3::before { /* group of 3 */
      width: 195px; 
      height: 50px;

      left: 90px;
      top: 15px;
  
    }
    
    .icon.group-top-4::before { /* group of 4 */
      width: 275px;
      height: 50px;

      left: 120px;
      top: 15px;
    }
  }