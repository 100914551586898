.map-nav {    
  flex: 0 0 auto;
  display: inline-block;
  width: 11vh;
  min-width: 11vh;
  height: 17.6vh;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  margin-right: 0px;
  background-size: cover;
  background-position: center;
  color: transparent; 
  text-align: center;
  padding: 0 0;
  transition: all 0.3s ease; 
}
  .map-nav:hover {
  opacity: 0.5;
  }
  

.map-nav.selected {
  border: 2px solid white;
}


html[data-bs-theme='light'] .map-nav.selected {
  border: 2px solid black;
}


html[data-bs-theme='dark'] .map-nav.selected {
  border: 2px solid white;
}

.scroll-container {
  display: flex; 
  gap: 1vh;
  overflow-y: auto;
  max-height: 73.5vh; /* 100 vh */
  max-width: 26vh;
  min-width: 26vh;
  margin-left: 0vh;
  align-content: flex-start;
  margin-right: 5vh;
}
@media (max-width: 768px) {
.scroll-container {
  flex-direction: row;
  max-width: 100%;
  max-height: 100%;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center; /* Center items horizontally */
  overflow-x: hidden;
  min-width: 0;
  gap: 1vh;
  margin-bottom: 0px;
}
.map-nav{
  text-align: center;
  max-height: 100%;
}
.nav-item{
  max-height: 100%;

}
}


  
.ancient-background { background-image: url('../images/ancient.png'); }
.anubis-background { background-image: url('../images/anubis.png'); }
.dust2-background { background-image: url('../images/dust2.png'); }
.inferno-background { background-image: url('../images/inferno.png'); }
.mirage-background { background-image: url('../images/mirage.png'); }
.nuke-background { background-image: url('../images/nuke.png'); }
.overpass-background { background-image: url('../images/overpass.png'); }
.vertigo-background { background-image: url('../images/vertigo.png'); }