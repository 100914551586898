.map-container {
  justify-content: left;
  align-items: center;
  height: 73.5vh; 
  flex-direction: column;
}

.map-wrapper {
  aspect-ratio: 1;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-grow: 0;
  position: relative;
  background-color: black;
  border: solid white 0.5px;
}

.svg, .svg-selected {
  fill: grey;
  width: 30px;
  height: 30px;
  z-index: 500;
  transition: transform 0.3s ease;
}

.svg {
  background-color: transparent;
}

.svg-selected {
  border-radius: 50px;
  background-color: blue;
  width: 40px;
  opacity: 1;
  animation: bounce 1.5s infinite ease;
}


.svg:not(.svg-selected):hover {
  fill: blue;
  transform: scale(1.2);
}

.svg-selected:hover{
  transform: scale(1.2);
}
.icon {
  z-index: 500;
}

.thrown-location {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: blue;
  opacity: 0.5;
  z-index: 400;
  transition: width 0.3s ease, height 0.3s ease, transform 0.3s ease;
}

.thrown-location:hover {
  width: 40px;
  height: 40px;
  transform: scale(1.2);
  cursor: pointer;
}


.line-connector {
  position: absolute;
  z-index: 300;
}


/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .map-container {
    height: auto; 
    flex: 1; 
  }

  .map-wrapper {
    height: auto; 
  }

}